import axios from 'axios'
//https://liu.api.dgjx0769.com
//https://api.lzzhtc.com/
//本地：192.168.16.80
const request = axios.create({
    baseURL: 'https://api.stsmartcity.com', //https://screen.dgjx0769.com',//https://liu.api.dgjx0769.com/
    timeout: 100000
})

request.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error) // 返回接口返回的错误信息
    }
)

export default request