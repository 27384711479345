import axios from 'axios'
import cookie from 'react-cookies'
import { message, Button, Space ,Modal} from 'antd';
import { BrowserRouter as Router,useHistory} from 'react-router-dom';
import { createHashHistory } from 'history'
import userStore from '@/store/user'
import { info } from 'console';


const request = axios.create({
    baseURL: 'https://api.stsmartcity.com/tvs-traffic/p-api/v1/', 
    timeout: 100000
})

request.interceptors.request.use(
    config => {
      let  token=cookie.load('vue_admin_template_token')
      if (token) {
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        config.headers['Authorization'] =token
      }
      return config
    },
    error => {
      // do something with request error
      console.log('返回错误',error) // for debug
      return Promise.reject(error)
    }
  )
request.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        //!error.response||error.response.status===401
        if(error.response.status===401){
          cookie.remove('vue_admin_template_token')
          const history = useHistory();
          history.push('/login')
           return
          }
       return Promise.reject(error) // 返回接口返回的错误信息
    }
)

export default request