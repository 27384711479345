import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback }  from 'react';
import cityStore from '@/store/city';
import { observer } from 'mobx-react'
import { useRequest } from 'ahooks'
import { GetRoadListByCity } from '@/services/api';
import Select from '@/components/Select';
import styles from './index.module.scss';

export type MapBarModeType = 'manageMenu'|'parklotRate'|'parklotincome'|'roadRate'|'roadincome'|'setdefault' | 'default' | 'alarm' | 'streetView' | 'heatMap' | 'gridMap' | 'staffMap' | 'more' | 'berth' | 'parklot' | 'parktight' | 'blind' |'';
export type num=(val: MapBarModeType) => void;
export type SetMode = (val: MapBarModeType) => void;
export type SetThreeDMode = (val: boolean) => void;
export type SetZoomLevel = (val: number) => void;


const MapBar:React.FC<{
  onModeChange: (mode: MapBarModeType,val: boolean) => void;
  onSkinChange: (val: boolean) => void;
  on3DChange: (val: boolean) => void;
  onTileLayerChange:(val: boolean) => void;
  sendIframeMessage: (params: {
    type: string;
    data: any;
    times?:number
  }) => void;
  className?: string;
  onmodedistance?:{
    distance: number;
    lonlat:  number[]
  };
  IsShowMapbar?:string;
  onMapbarstate?:boolean;
  ref: any;
  menu:number;
}> = forwardRef(({onModeChange,onSkinChange,on3DChange,sendIframeMessage, IsShowMapbar,onTileLayerChange,className,onmodedistance,onMapbarstate,menu},ref) => {
  const [zoomLevel,setZoomLevel] = useState(1);
  const [mode,setMode] = useState<MapBarModeType>('');
  const [alarm,setAlarm] = useState(1);
  const[isShowMapBar,setShowMapBar]=useState(true);
  const [skinOptional,setSkinOptinal] = useState(false);
  const [threeDMode,setThreeDMode] = useState(true);//切换2d和3d显示
  const [road,setRoad] = useState('');
  const [layerMode ,setLayerMode] =useState(false)
  const [moreMode,setMoreMode] =useState(false)
  const [roadMode,setRoadMode] =useState(false)
  const [alarmMode,setAlarmMode] =useState(false)
  const [streetMode,setStreetMode] =useState(false)
  const [berthMode,setBerthMode] =useState(false)
  const [parklotMode,setParklotMode] =useState(false)  
  const [staffMode,setStaffMode] =useState(false)
  const [gridMode,setGridMode] =useState(false)
  const [heatMode,setHeatMode] =useState(false)  
  const [setdefaultMode,setSetdefaultMode] =useState(true)  

  
  const [parklotRateMode,setparklotRateMode] =useState(false)  
  const [parklotincomeMode,setparklotincomeMode] =useState(false)
  const [roadRateMode,setroadRateMode] =useState(false)
  const [roadincomeMode,setroadincomeMode] =useState(false)

  const [clickmapMode,setClickmapMode] =useState(0)  
  const [entermapMode,setEntermapMode] =useState(0)  
  const [heardstateMode,setheardstateMode] =useState(false)  
  const [clickMode,setclickMode] =useState(false)  
  const [clickmenutime,setclickmenutime] =useState(0) 
  const [isdeviceChange,setisdeviceChange] =useState(false) 

  let disheight=onmodedistance?onmodedistance['distance']:0
  const trialRunStatus='1'
  const receiveMessage =  ( event: any ) => {
    if(event!==undefined &&event.data && event.data.name){
        setClickmapMode(event.data.data)
    }
};

  const getMessage=()=>{
    window.addEventListener("message",receiveMessage,false) 
  }

  getMessage()
  const { data: roadList = [] } = useRequest(useCallback(() => {
    return GetRoadListByCity(cityStore.areaCode,trialRunStatus)
  },[]), {
    refreshDeps: [cityStore.areaCode],
    formatResult: res => res.data,
    onSuccess: (data: API.RoadList) => {
      let road = data.find(item => item.isDefault === 1);
     // console.log('路劲',data)
      if(!road) {
        road = data[0];
      }
      
      setRoad(road?.roadSectionId || '');
      // console.log(road.roadSectionId)
    //  console.log(cityStore.areaCode, cityStore.coordinates?.slice())
     
      sendIframeMessage({
        type: 'cityChange',
        data: {
          areaCode: cityStore.areaCode,
          coordinates: cityStore.coordinates?.slice(),
          roadSectionId: road?.roadSectionId || ''
        }
      })
     
    }
  })


  const changeMode = (currentMode: MapBarModeType,val: boolean) => {
      setclickMode(true)
      setheardstateMode(false) 
      
      if(currentMode!='alarm' &&alarmMode){
        (menu===2||menu===0)&& changeMode('alarm',false)
      }
      
     
    switch (currentMode) {
      case 'default':
        if(val !== roadMode) { 
          setRoadMode(val)
          setclickmenutime(1)
         }
        //  if(roadMode){
        //   setShowMapBar(false)
        //  }else{
        //   setShowMapBar(true)
        //  }
        break;
      case 'roadRate':  //'parklotRate'|'parklotincome'|'roadRate'|'roadincome'
        if(val !== roadMode) { 
          setRoadMode(val)
        // setroadRateMode(val)
          setclickmenutime(1)
        }
      break;
        case 'roadincome':  
        if(val !== roadMode) { 
          setRoadMode(val)
         // setroadincomeMode(val)
          setclickmenutime(1)
        }
        break;
      case 'parklotRate':
        if(val !== parklotMode) {
          setParklotMode(val)
         // setparklotRateMode(val)
          setclickmenutime(1)
          }
        break;  
      case 'parklotincome':
          if(val !== parklotMode) {
            setParklotMode(val)
            //setparklotincomeMode(val)
            setclickmenutime(1)
            }
          break;    
      case 'alarm':
        
        if(val !== alarmMode) {
          setAlarmMode(val)
          setclickmenutime(1)
         }
         
         setParklotMode(false)
         setHeatMode(false)
         setRoadMode(false)
        setStreetMode(false)
        setBerthMode(false)
        setStaffMode(false)
        setGridMode(false)

        break;
      case 'streetView':
        if(val !== streetMode) {
          setclickmenutime(1)
          setStreetMode(val)
         }
        
        break;
      case 'berth':
        if(val !== berthMode) {
          setclickmenutime(1)
          setBerthMode(val)
         }
        break;  
      case 'parklot':
        if(val !== parklotMode) {
          setParklotMode(val)
          setclickmenutime(1)
         }
        break;
        case 'staffMap':
          if(val !== staffMode) {
            setclickmenutime(1)
            setStaffMode(val)
           }
          break;
        case 'heatMap':
          if(val !== heatMode) {
            setclickmenutime(1)
            if(clickmapMode==1&&entermapMode==3){
              setHeatMode(false)
            }else{
              setHeatMode(val)
            }
            
            }
          break;   
        case 'gridMap':
          if(val !== gridMode) {
            setclickmenutime(1)
            setGridMode(val)
            }
          break;   
        case 'setdefault':
            setclickmenutime(1)
            entermapMode!=0&&setRoadMode(true)
            entermapMode!=0&&setHeatMode(true)
            entermapMode!=0&&setParklotMode(true)
            setAlarmMode(false)
            setStreetMode(false)
            setBerthMode(false)
            setStaffMode(false)
            setGridMode(false)       
           break;       
                 
     }
     
     setMode(currentMode)
     onModeChange(currentMode,val);
     setEntermapMode(entermapMode+1)

  }

  const skinChange = (val: boolean) => {
    onSkinChange(!val);
    setSkinOptinal(val);
  }

  const dimensionChange = (val: boolean) => {
    if(val !== threeDMode) {
      on3DChange(val);
      setThreeDMode(val);
    }
  }

  /**TileLayer 更换地图模式 layerMode */
  const tileLayerChange = (val: boolean) => {
    if(val !== layerMode) {
      onTileLayerChange(val);
     setLayerMode(val);
    }
  }

  const showMore =(val:boolean)=>{
    if(val !==moreMode){
      setMoreMode(val);
    }
  }

  const streetChange = (val: string) => {
    setRoad(val);
    setEntermapMode(3)
    sendIframeMessage({
      type: 'focusStreet',
      data: {
        roadSectionId: val
      }
    })
  }

  useEffect(() => {
    //点击设备时显示
    if(IsShowMapbar=='deviceChange'){
        setisdeviceChange(true)
        setEntermapMode(3)
        setParklotMode(false)
        setHeatMode(false)
        setRoadMode(false)
        setStreetMode(false)
        setBerthMode(false)
        setStaffMode(false)
        setGridMode(false)
        setAlarmMode(false)

    }
    
    //点击还原时显示
    if(menu===0&&onMapbarstate&&IsShowMapbar=='setdefault'){
      setRoadMode(true)
      setHeatMode(true)
      setParklotMode(true)
      setEntermapMode(3)
      setAlarmMode(false)
      setStreetMode(false)
      setBerthMode(false)
      setStaffMode(false)
      setGridMode(false)
    
    }
    if(menu===0&&disheight>60000 ){
      if(clickmapMode==1&&!clickMode){
        //点击地图时显示
      setRoadMode(true)
      setHeatMode(true)
      setParklotMode(true)
      setEntermapMode(3)
    }

  }
    
    if(disheight>60000 && entermapMode==1){
      //除了alarm 其他多选
       
        if(mode!='alarm'){
          if(menu===0||menu===2){
            mode!='default'&&changeMode('default',!roadMode)
            mode!='parklot'&&changeMode('parklot',!parklotMode)
            mode!='heatMap'&&changeMode('heatMap',!heatMode)
          }else if(menu===1){
            mode!='roadincome'&&changeMode('roadincome',!roadMode)
            mode!='parklotincome'&&changeMode('parklotincome',!parklotMode)
            mode!='heatMap'&&changeMode('heatMap',!heatMode)

          }else if(menu===3){
            mode!='roadRate'&&changeMode('roadRate',!roadMode)
            mode!='parklotRate'&&changeMode('parklotRate',!parklotMode)
            mode!='heatMap'&&changeMode('heatMap',!heatMode)

          }
      }
    
  }
  
  

    
 
if(disheight<60000||isdeviceChange ){
  
  if((menu===0&&disheight!=0&&clickmenutime==0&&IsShowMapbar=='manageMenu')){
    //console.log('切换到了第一位',menu,disheight,IsShowMapbar,onMapbarstate)
        //changeMode('setdefault', true);
        sendIframeMessage({
          type: 'setdefault',
          data: true,
          times:999999999
        })
        setRoadMode(true)
      setHeatMode(true)
      setParklotMode(true)
      setEntermapMode(3)
      setAlarmMode(false)
      setStreetMode(false)
      setBerthMode(false)
      setStaffMode(false)
      setisdeviceChange(false)
        return
      }
    
  if((menu===1&&clickmenutime==0)||(menu===3&&clickmenutime==0)){
    setRoadMode(true)
    changeMode('heatMap', true);
    setParklotMode(true)
    setEntermapMode(3)
    return
  }
  if(menu===2&&clickmenutime==0){
    //setAlarmMode(true)
    changeMode('alarm', true);
    setParklotMode(false)
     setHeatMode(false)
     setRoadMode(false)
    setEntermapMode(4)
    return

  }
  setclickmenutime(0)

}

   // setThreeDMode(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickmapMode,entermapMode,mode,IsShowMapbar,onMapbarstate,menu]);
  
 const roadicon=menu ===1 ? 'roadincome' : menu ===3 ? 'roadRate':'default'
 const parkicon=menu ===1 ? 'parklotincome' : menu ===3 ? 'parklotRate':'parklot'
  useImperativeHandle(ref, () => ({
    threeDMode,
    mode,
    setMode,
    setThreeDMode,
    setZoomLevel,
    entermapMode
  }))
  return (
    <div className={`${styles.map_bar} ${styles.map_bar_main} `}>

      {
        roadList&&
          <div className={styles.street_list}>
            <p className={styles.street_list_number}>{roadList.length||0}</p>
            <p className={styles.street_list_text}>城市街道</p>
            <Select
              className={styles.street_list_select}
              dropdownClassName={styles.street_list_select_dropdown}
              options={roadList.map((item,_index) => ({
                label: item.roadSectionName,
                value: item.roadSectionId
              }))}
              value={road}
              onChange={streetChange}
            />
          </div>
        
      }
      {/* 其他底部按钮界面显示 */}
      <div className={menu <1 ? 'hide' : ''  }> 
        <button  onClick={() =>changeMode(roadicon,!roadMode)} title="道路">
          <img src={roadMode ? '/img/map/Sdaolu.png' : '/img/map/daolu.png'} alt=""/>
        </button>
        <button onClick={() => changeMode(parkicon,!parklotMode)} title="接入停车场">
          <img src={parklotMode? '/img/map/parking-active.png' : '/img/map/parking.png'} alt="停车场"/>
        </button>

        <button onClick={() =>changeMode('heatMap',!heatMode)} title="热力">
          <img src={heatMode? '/img/map/icon_map_heat_active.png' : '/img/map/icon_map_heat.png'} alt=""/>
        </button>
        <div className={menu ===2 ? '' : 'hide'  }> 
          <button  onClick={() => changeMode('alarm',!alarmMode)} title="报警">
            <img src={alarmMode ? '/img/map/icon_map_alarm_active.png' : '/img/map/icon_map_alarm.png'} alt=""/>
          </button>
        </div>
      </div>
       {/* 停车资源显示 */}
     <div className={menu >0 ? 'hide' : ''  }> 
      <button  onClick={() =>changeMode('default',!roadMode)} title="道路">
        <img src={roadMode ? '/img/map/Sdaolu.png' : '/img/map/daolu.png'} alt=""/>
      </button>
      <button onClick={() => changeMode('parklot',!parklotMode)} title="接入停车场">
        <img src={parklotMode? '/img/map/parking-active.png' : '/img/map/parking.png'} alt="停车场"/>
      </button>

      <button onClick={() =>changeMode('heatMap',!heatMode)} title="热力">
        <img src={heatMode? '/img/map/icon_map_heat_active.png' : '/img/map/icon_map_heat.png'} alt=""/>
      </button>
      <button onClick={() => changeMode('staffMap',!staffMode)} title="巡检员">
        <img src={staffMode? '/img/map/icon_map_staff_active.png' : '/img/map/icon_map_staff.png'} alt=""/>
      </button>
      <button onClick={() => changeMode('gridMap',!gridMode)} title="网格">
        <img src={gridMode ? '/img/map/icon_map_grid_active.png' : '/img/map/icon_map_grid.png'} alt=""/>
      </button>

      <button onClick={() => changeMode('alarm',!alarmMode)} title="报警">
        <img src={alarmMode ? '/img/map/icon_map_alarm_active.png' : '/img/map/icon_map_alarm.png'} alt=""/>
      </button>
      
      <button onClick={() => changeMode('setdefault',setdefaultMode)} title="恢复默认">
        <img src={'/img/map/skin.png'} alt="恢复默认"/>
      </button>
      
      <button onClick={() => showMore(!moreMode)} title="更多">
        <img src={moreMode ? '/img/map/more_active.png' : '/img/map/more.png'} alt=""/>
      </button>
      
      <div className={`${styles.map_bar_box} ${styles.map_bar_main} ${moreMode ? '' : 'hide'}`} >
      <button onClick={() => dimensionChange(!threeDMode)} title={`切换${threeDMode ? '2D' : '3D'}`}>
        <img src={threeDMode ? '/img/map/3D@2x.png' : '/img/map/icon_map_2d.png'} alt=""/>
      </button>
      
      <button onClick={() => skinChange(!skinOptional)} title="皮肤切换">
        <img src={skinOptional ? '/img/map/reset_active.png' : '/img/map/reset.png'} alt=""/>
      </button>
      <button onClick={() => tileLayerChange(!layerMode)} title={`切换${layerMode ? '卫星' : '地图'}`}>
        <img src={layerMode ? '/img/map_street.png' : '/img/map_street2.png'} alt=""/>
      </button> 
    
      </div>
      </div>
    </div>
  )
})

export default observer(MapBar);